
import Vue from 'vue';
import { Nullable } from '@/shared/app/app.utils';
import { EslConfig } from '@/shared/store/models/EslConfig';
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import EslProductForm from '../forms/esl-product-form/EslProductForm.vue';
import { EslInfo } from '@/shared/store/models/EslInfo';
import HttpMixin from '@/shared/http/http.mixin';
import EslDishesForm from '../forms/esl-dishes-form/EslDishesForm.vue';
import { load } from 'dotenv';

const eslForms = {
  EslProductForm,
  EslDishesForm,
};

@Component({
  components: {
    ...eslForms,
  },
})
export default class EslEditionCard extends mixins(Vue, HttpMixin) {
  @Prop({ default: null })
  eslConfig!: Nullable<EslConfig>;

  @Prop({ default: null })
  eslInfo!: Nullable<EslInfo>;

  imageSrc = '';

  mounted() {
    this.loadImage();
  }

  loadImage() {
    if (this.eslConfig)
      this.http.getEslImage(this.eslConfig.labelCode).then((blob) => {
        this.imageSrc = URL.createObjectURL(blob);
      });
  }

  get aspectRatio() {
    if (this.eslInfo) return this.eslInfo.width / this.eslInfo.height;
    return 16 / 9;
  }

  @Watch('eslConfig')
  eslConfigWatch() {
    this.loadImage();
  }
}
